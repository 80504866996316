import { Property } from "csstype";

export interface BasicTheme {
  barheight: string;
  backgroundColor: Property.BackgroundColor;
  codeColor: Property.Color;
}

export const themeA: BasicTheme = {
  barheight: "4rem",
  backgroundColor: "#cdcfcc",
  codeColor: "#d63384",
};
