import cookie from "react-cookies";
import { CookieSerializeOptions } from "cookie";

type UserCredCookies = "jwt" | "idUser";

type CookieName = UserCredCookies;

type CookieObject = Partial<Record<CookieName, string | number | Object>>;

export type CookieDataArray = [CookieName, string | number | Object];

export default class CookieMonster {
  /**
   *
   * @param cookieName name of the Cookie
   * @returns `string`
   */
  static loadCookie(cookieName: CookieName) {
    return cookie.load(cookieName);
  }

  /**
   *
   * @param cookieName name of the Cookie
   * @param data data you want to put in
   * @param options optional options you want to put in cookie
   */
  static saveCookie(
    cookieName: CookieName,
    data: string | number | object,
    options?: CookieSerializeOptions
  ) {
    cookie.save(cookieName, data, {
      path: "/",
      secure: true,
      sameSite: true,
      ...options,
    });
  }
  /**
   *
   * @param cookieName cookie name to be removed
   */
  static removeCookie(cookieName: CookieName) {
    cookie.remove(cookieName, { path: "/", secure: true, sameSite: true });
  }
  /**
   * Eat them all! OMNOMNOMNOMNOM!
   * @param cookieNames all cookie names you want to eat (in Array)
   * @example `['menu','permission']`
   */
  static removeAllTheseCookies(cookieNames: CookieName[]) {
    cookieNames.forEach((name, index) => {
      cookie.remove(name);
    });
  }
  /**
   *
   * @param cookieNames cookie names to load (in Array)
   * @returns `CookieObject`
   */
  static loadAllTheseCookies(cookieNames: CookieName[]) {
    let data: CookieObject = {};
    cookieNames.forEach((name, index) => {
      data[name] = cookie.load(name);
    });
    return data;
  }

  /**
   * Save all provided cookie names with option `{path:'/'}`
   * @param cookieDatas Array of `[cookieName, data]`
   * @example [ ['menu','simelekete'], ['division_id', 2]]
   */
  static saveAllTheseCookies(cookieDatas: CookieDataArray[]) {
    // Object.keys(cookieDatas).forEach((val,i)=>{
    //   const name: CookieName = val as CookieName
    //   let data = cookieDatas[name]
    //   if(data) cookie.save(val, data,{path:'/'})
    // })

    // for(let property in cookieDatas){
    //   // const name = property as CookieName;
    //   let val = cookieDatas[property as CookieName];
    //   if(typeof val !== 'undefined'){

    //     cookie.save(property, val,{path:'/'})
    //   }
    // }
    cookieDatas.forEach(([name, data]) => {
      cookie.save(name, data, { path: "/" });
    });
  }
}
