import React from "react";

// import './App.css';
import { Routes, Route, Navigate, HashRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import AuthHOC from "./components/AuthHOC";
import Layout from "./components/Layout";
import { ListOfRoutes } from "./config/nav";
import NoMatch from "./pages/NoMatch";

const LoginPage = React.lazy(() => import("./pages/LoginPage"));
const CitiesOverviewPage = React.lazy(
  () => import("./pages/CitiesOverviewPage")
);
const CityDetailPage = React.lazy(() => import("./pages/CityDetailPage"));
const LangsOverviewPage = React.lazy(() => import("./pages/LangsOverviewPage"));

function App() {
  return (
    <div className="App">
      <HashRouter>
        <Routes>
          <Route
            path="/"
            element={
              <AuthHOC>
                <Layout />
              </AuthHOC>
            }
          >
            <Route
              index
              element={<Navigate replace to={ListOfRoutes.cityoverview} />}
            />
            <Route
              path={ListOfRoutes.cityoverview}
              element={
                <React.Suspense fallback={<>loading...</>}>
                  <CitiesOverviewPage />
                </React.Suspense>
              }
            />
            <Route
              // path="/cities/:cityid"
              path={`${ListOfRoutes.cityoverview}/:cityid`}
              element={
                <React.Suspense fallback={<>loading...</>}>
                  <CityDetailPage />
                </React.Suspense>
              }
            />
            <Route
              path={ListOfRoutes.langsoverview}
              element={
                <React.Suspense fallback={<>loading...</>}>
                  <LangsOverviewPage />
                </React.Suspense>
              }
            />
          </Route>
          <Route
            path={ListOfRoutes.loginpage}
            element={
              <React.Suspense fallback={<>loading...</>}>
                <LoginPage />
              </React.Suspense>
            }
          />
          <Route path="*" element={<NoMatch />} />
        </Routes>
      </HashRouter>
      <ToastContainer />
    </div>
  );
}

export default App;
