import React from "react";
import { createUseStyles } from "react-jss";
import { useNavigate, useLocation } from "react-router-dom";
import { useTheme } from "theming";
import { BasicTheme } from "../assets/theme";
import SimpleBar from "simplebar-react";
import { ListOfRoutes } from "../config/nav";
import CookieMonster from "../api/CookieMonster";

interface Props {}

const useStyles = createUseStyles((theme: BasicTheme) => {
  return {
    logoutButton: {
      border: 0,
      borderRadius: "1rem 0 0 1rem",
    },
    navItem: {
      paddingLeft: "1rem",
      "&:not(:first-child)": {
        marginTop: "15px",
      },
      "&:not(:last-child)": {
        marginBottom: "15px",
      },
      "&> button": {
        padding: ".5rem",
        borderRadius: "1rem 0 0 1rem",
      },
    },
  };
});

interface ANav {
  to: string;
  name: string;
}

const nav: ANav[] = [
  {
    to: ListOfRoutes.cityoverview,
    name: "Cities",
  },
  {
    to: ListOfRoutes.langsoverview,
    name: "Languages",
  },
];

const Sidebar: React.FunctionComponent<Props> = (props) => {
  const theme = useTheme<BasicTheme>();
  const classes = useStyles();
  const location = useLocation();
  const navigate = useNavigate();
  // const location = useLocation();
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        flex: 1,
        // maxHeight: "100vh",
      }}
    >
      <div
        style={{
          height: theme.barheight,
          flexBasis: theme.barheight,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          backgroundColor: undefined,
          // backgroundColor: "cyan",
        }}
      >
        <p style={{ textAlign: "center", margin: 0, fontWeight: "bold" }}>
          BibToGo Admin App
        </p>
      </div>
      <SimpleBar
        style={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
          overflowY: "auto",
        }}
      >
        {nav.map(({ to, name }) => {
          return (
            <div
              className={classes.navItem}
              key={to}
              style={{ display: "flex" }}
            >
              <button
                style={{ flex: 1, border: 0 }}
                onClick={() => navigate(to)}
              >
                {name}
              </button>
            </div>
          );
        })}
      </SimpleBar>
      <div
        style={{
          flexBasis: theme.barheight,
          display: "flex",
          flexDirection: "column",
          marginLeft: "1rem",
        }}
      >
        <button
          className={classes.logoutButton}
          onClick={() => {
            CookieMonster.removeCookie("jwt");
            navigate(ListOfRoutes.loginpage, {
              state: {
                from: location,
              },
            });
          }}
        >
          Logout
        </button>
      </div>
    </div>
  );
};

export default Sidebar;
